<template>
  <div class="IssueCrm8">

    <h2>Informe um pouco sobre você:</h2>
    <div class="form-group-container">
     
    <div class="form-group">
      <label for="name" >Nome*</label>
      <input type="text" id="name" v-model="formData.name" required  class="campo-Str-Class "/>
    </div>
    
    <div class="form-group">
      <label for="email">Email*</label>
      <input type="email" id="email" v-model="formData.email" required class="campo-Str-Class  " />
    </div>
  </div>
  <div class="box-input"> 
    <div class="form-group">
      <label for="birthdate">Data de Nascimento*</label>
      <input type="text" id="birthdate" v-model="formData.birthdate" placeholder="DD/MM/AAAA" required class="campo-Class " />
    </div>
    <div class="form-group">
      <label for="phone">Número de Telefone*</label>
      <input type="text" id="phone" v-model="formData.phone" placeholder="(__) ____-____" required  class="campo-Class " />
    </div>
    <div class="form-group">
      <label for="gender">Sexo*</label>
      <select id="gender" v-model="formData.gender" required class="select-Class"> 
        <option value="" disabled selected>Selecione</option>
        <option value="male">Masculino</option>
        <option value="female">Feminino</option>
        <option value="other">Outro</option>
      </select>
    </div>
  </div>
 
</div>

</template>
<script>

import IssueCrm8dados from '@/assets/dados.json';
export default {
  name: "IssueCrm8",
  version: '1.0.0',
  createdDate: '2024-10-13',
  lastUpdated: '',


  data() {
    return {
      IssueCrm8textos: IssueCrm8dados, // Assumindo que você carrega o JSON como um objeto de dados chamado 'dados',
      formData: {
        name: '',
      },
      selectedDonation: null,
    };


  }

}


</script>
<style scoped>
/* Importa a fonte Roboto do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

/* Container principal do formulário */
.form-group-container {
  display: flex;
  flex-wrap: wrap; /* Permite reorganizar os campos em telas menores */
  gap: 20px; /* Espaço entre os grupos de campos */
  max-width: 800px; /* Largura máxima do formulário */
  margin: 0 auto; /* Centraliza o formulário */
  padding: 10px; /* Espaçamento interno para uma melhor aparência */
}

.form-group {
  display: flex;
  flex-direction: column; /* Coloca os labels acima dos campos para dispositivos menores */
  flex: 1 1 250px; /* Flexível para se ajustar conforme necessário com uma largura mínima */
  min-width: 150px; /* Largura mínima para cada campo */
  margin-bottom: 12px; /* Espaçamento entre os campos */
}

/* Estilo para os rótulos */
label, h2{
  font-family: 'Roboto', sans-serif; /* Fonte desejada */
  
  font-weight: 300; /* Peso da fonte mais fino */
  margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
}
.form-group label{
  font-size: 12px; /* Tamanho de fonte ajustado */
}
h2{
  font-size: 16px; /* Tamanho de fonte ajustado */
}
/* Estilo para os campos de entrada e seleção */
input,
select {
  width: 100%; /* Largura total para responsividade */
  padding: 8px; /* Espaço interno */
  font-size: 12px; /* Tamanho de fonte para o texto dentro dos campos */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  font-weight: 300; /* Peso da fonte mais leve */
}

/*
Teste de grupos
*/

/* Estilo para garantir que os três campos fiquem na mesma linha */
.box-input {
  display: flex; /* Usa flexbox para alinhar os campos em linha */
  gap: 20px; /* Espaçamento entre os campos */
  flex-wrap: wrap; /* Permite que os campos reorganizem em telas menores */
  margin-bottom: 15px; /* Espaçamento na parte inferior */
}

.box-input .form-group {
  flex: 1; /* Permite que os campos se ajustem ao espaço disponível */
  min-width: 150px; /* Largura mínima para manter campos consistentes */
}

.box-input input, 
.box-input select {
  width: 100%; /* Garante que cada campo ocupe toda a largura disponível dentro do grupo */
  padding: 8px; /* Espaçamento interno */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  font-size: 12px; /* Ajuste do tamanho de fonte */
  font-weight: 300; /* Peso da fonte mais fino */
}

/* Ajuste para telas menores */
@media (max-width: 600px) {
  .box-input {
    flex-direction: column; /* Empilha os campos verticalmente em telas menores */
  }

  .box-input .form-group {
    width: 100%; /* Cada campo ocupa 100% da largura disponível */
  }
}


input:focus,
select:focus {
  border-color: #36806D; /* Cor de borda ao focar */
  outline: none; /* Remove o contorno padrão */
}

/* Ajustes para telas menores */
@media (max-width: 600px) {
  .form-group-container {
    flex-direction: column; /* Empilha os campos em telas pequenas */
  }

  .form-group {
    flex: 1 1 100%; /* Cada campo ocupa 100% da largura disponível */
  }
}
</style>
